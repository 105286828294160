.portfolio {
  &__content {
    padding: 20px;
    width: 100%;
    &__filter {
      display: inline-block;
      margin: 0 auto;
      border-radius: 25px;
      border: 1px dashed var(--yellow-theme-main-color);
      li {
        font-size: 16px;
        display: inline-block;
        cursor: pointer;
        position: relative;
        color: var(--yellow-theme-main-color);
        text-transform: uppercase;
        padding: 12px 20px;
        overflow: hidden;
        transition: all 0.5s ease;
        &.active {
          background: var(--yellow-theme-main-color);
          color: var(--yellow-theme-sub-text-color);
          font-weight: 600;
          border-radius: 25px;
        }
      }
    }
    &__cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: auto;
      gap: 1rem;
      margin-top: 10px;
      &__item {
        height: 366px;
        cursor: pointer;
        position: relative;
        &:hover .overlay {
          opacity: 1;
        }
        &__img-wrapper {
          height: 100%;
          a {
            height: 100%;
            img {
              height: inherit;
              object-fit: cover;
              width: 100%;
            }
          }
        }
        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          transition: 0.5s ease;
          background-color: var(--yellow-theme-main-color);
          div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            p {
              color: #000;
              margin: 0px;
              font-size: 4rem;
              position: relative;
              overflow: hidden;
              white-space: nowrap;
              animation: typingEffect 1s steps(30, end);
            }
            button {
              cursor: pointer;
              padding: 10px 70px;
              font-size: 2rem;
              background: transparent;
              color: #000;
              border: 1.5px solid #000;
              border-radius: 4px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 480) {
  .portfolio__content__filter > li {
    font-size: 11px;
  }
  .portfolio__content__cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (min-width: 481) and (max-width: 767px) {
  .portfolio__content__filter > li {
    font-size: 14px;
  }
  .portfolio__content__cards {
    grid-template-columns: repeat(2, 1fr);
    &__item {
      .overlay {
        div > p {
          font-size: 2.5rem;
        }
      }
    }
  }
}
@keyframes typingEffect {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
